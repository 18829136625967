import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PageWrapper from "../components/PageWrapper.js"

const FeaturedPage = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: {
              frontmatter: {
                published: { eq: true }
                display: { in: ["featured"] }
              }
            }
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                  client
                  date(formatString: "dddd DD MMMM YYYY", locale: "fr-FR")
                  type
                  videos {
                    url
                    poster {
                      name
                      publicURL
                      colors {
                        vibrant
                      }
                      childImageSharp {
                        fluid(quality: 90, maxHeight: 1200) {
                          srcSetWebp
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  published
                  poster {
                    name
                    publicURL
                    colors {
                      vibrant
                    }
                    childImageSharp {
                      fluid(quality: 90, maxHeight: 1200) {
                        srcSetWebp
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <PageWrapper data={data} {...props} />
        </>
      )}
    />
  )
}
export default FeaturedPage
